import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

import { ignoreErrors } from 'sentry.common.config';

const {
  publicRuntimeConfig: {
    sentry: { dsn },
  },
} = getConfig();

Sentry.init({
  dsn,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  sampleRate: 0.33,
  ignoreErrors,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
