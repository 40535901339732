import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { LocalizationProvider } from 'ilenia';
import { ThemeProvider } from 'styled-components';

import { projectTheme } from '@bscom/styling/projectTheme';
import componentLibTranslations from '@bscom/localisation/en/strings.json';

import translations from '../localisation/en/strings.json';

function MyApp({ Component, pageProps = {} }) {
  const router = useRouter();

  const mergedTranslations = {
    ...componentLibTranslations,
    ...translations,
  };

  useEffect(() => {
    const handleRouteChange = () => {
      window?.analytics?.page({
        locale: 'en-US',
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, []);
  return (
    <ThemeProvider theme={projectTheme}>
      <LocalizationProvider locale="en-US" translations={mergedTranslations}>
        <Component {...pageProps} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default MyApp;
